export const homeIcon = {
  label: 'B',
  src: '/static/icons/B_letter.png'
};


/*
* Front end skills
*/
export const frontEndSkillsList = [{
  label: 'React JS',
  src: '/static/icons/reactjs.svg',
},{
  label: 'Angular JS',
  src: '/static/icons/angular.svg',
}, {
  label: 'Javascript',
  src: '/static/icons/javascript.png',
}, {
  label: 'Sass',
  src: '/static/icons/sass.svg'
}, {
  label: 'HTML5',
  src: '/static/icons/html.png'
}, {
  label: 'CSS3',
  src: '/static/icons/css3.png'
},{
  label: 'Material UI',
  src: '/static/icons/material.svg'
}, {
  label: 'Bootstrap',
  src: '/static/icons/bootstrap.png'
},  {
  label: 'Python',
  src: '/static/icons/python.png'
}, ];


/*
* Back end skills
*/
export const backEndSkillsList = [ {
  label: 'Node JS',
  src: '/static/icons/nodejs.svg',
}, {
  label: 'Express JS',
  src: '/static/icons/express.png'
},  {
  label: 'PostgreSQL',
  src: '/static/icons/postgres.png'
},  {
  label: 'Mongo DB',
  src: '/static/icons/mongodb.svg'
},{
  label: 'Pg Admin',
  src: '/static/icons/postgres.png'
},  {
  label: 'My SQL',
  src: '/static/icons/mySql.png'
},];

/*
* Data base skills
*/
export const dataBaseSkillsList = [ {
  label: 'Graphql',
  src: '/static/icons/graphql.png'
},  {
  label: 'Rest API',
  src: '/static/icons/restApi.png'
},];


/*
* cloud skills
*/
export const cloudSkillsList = [ {
  label: 'AWS',
  src: '/static/icons/aws.png',
}, {
  label: 'Netlify',
  src: '/static/icons/netlify.png'
},  {
  label: 'Heroku',
  src: '/static/icons/heroku.png'
}];

/*
* Contact details
*/
export const contactDetails = [{
  iconSrc: "static/icons/gmail.svg",
  type: "Email",
  data: "blazemathew987@gmail.com",
  href: "mailto:blazemathew987@gmail.com?subject='Hello !'"
}, {
  iconSrc: "static/icons/linkedin.svg",
  type: "LinkedIn",
  data: "Blaze Mathew",
  href: "https://www.linkedin.com/in/blaze-mathew"
},
{
  iconSrc: "static/icons/phone.png",
  type: "Contact Number",
  data: "+91 8111 866 166",
  href: "tel:+918111866166"
}];

