import React, { Suspense } from "react";
import CustomNav from "./NavBar";
import { Container, Row, Col, Spinner,Card, Image } from "react-bootstrap";
import {  backEndSkillsList,frontEndSkillsList,dataBaseSkillsList,cloudSkillsList } from "../utils/helperList";

const Skills = () => {

  return (
    <>
      
      <Container>
        <CustomNav />
        <div>
        <Suspense fallback={<Spinner animation="border" variant="secondary" />}>
          <div className="text-center">
            <h1 className="display-4 fw-bold text-primary">My Skills</h1>
            <p className="lead text-muted">A showcase of my technical expertise and competencies across various domains</p>
          </div>
          <Row xs={1} md={2} lg={4} className="g-4 justify-content-center">
            {frontEndSkillsList.map((skill) => (
              <Col key={skill.label}>
                <Card className="border-0 shadow-lg h-100 d-flex flex-column align-items-center justify-content-center bg-light">
                  <Image
                    src={skill.src}
                    fluid
                    roundedCircle
                    alt={skill.label}
                    style={{ width: "100px", height: "100px", objectFit: "contain" }}
                  />
                  <Card.Title className="mt-3 text-primary">{skill.label}</Card.Title>
                </Card>
              </Col>
            ))}

            {backEndSkillsList.map((skill) => (
              <Col key={skill.label}>
                <Card className="border-0 shadow-lg h-100 d-flex flex-column align-items-center justify-content-center bg-light">
                  <Image
                    src={skill.src}
                    fluid
                    roundedCircle
                    alt={skill.label}
                    style={{ width: "100px", height: "100px", objectFit: "contain" }}
                  />
                  <Card.Title className="mt-3 text-primary">{skill.label}</Card.Title>
                </Card>
              </Col>
            ))}

            {dataBaseSkillsList.map((skill) => (
              <Col key={skill.label}>
                <Card className="border-0 shadow-lg h-100 d-flex flex-column align-items-center justify-content-center bg-light">
                  <Image
                    src={skill.src}
                    fluid
                    roundedCircle
                    alt={skill.label}
                    style={{ width: "100px", height: "100px", objectFit: "contain" }}
                  />
                  <Card.Title className="mt-3 text-primary">{skill.label}</Card.Title>
                </Card>
              </Col>
            ))}

            {cloudSkillsList.map((skill) => (
              <Col key={skill.label}>
                <Card className="border-0 shadow-lg h-100 d-flex flex-column align-items-center justify-content-center bg-light">
                  <Image
                    src={skill.src}
                    fluid
                    roundedCircle
                    alt={skill.label}
                    style={{ width: "100px", height: "100px", objectFit: "contain" }}
                  />
                  <Card.Title className="mt-3 text-primary">{skill.label}</Card.Title>
                </Card>
              </Col>
            ))}
          </Row>
        </Suspense>
        </div>
      </Container>
    </>
  )
};

export default Skills;
