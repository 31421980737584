import React from "react";
import CustomNav from "./NavBar";
import { Container, Row, Col, CardGroup, Card } from "react-bootstrap";
import { contactDetails } from "../utils/helperList";

const Contact = () => {
  return (
    <>
      
      <Container className="home-container" role="main">
      <CustomNav />
      <h2 className="color-custom-blue ml-lg-5 proj-slide mt-5">Contact Me!</h2>
        <Row className="mt-3 mt-lg-4 mb-3">
          <Col xs={12} md={12}>
            <CardGroup className="contact">
              {contactDetails.map(obj => (
                <Card className="contact-card m-4" key={obj.type}>
                  <a href={obj.href} className="text-link" target="_blank" rel="noreferrer">
                    <div className="shadow d-flex justify-content-center text-center flex-column py-3">
                      <div className="m-4">
                        <img className="contact-img" src={obj.iconSrc} alt={obj.type} loading="lazy" />
                      </div>
                      <span className="text-muted mt-2 mb-3">{obj.type}</span>
                      <span className="color-custom-blue">{obj.data}</span>
                    </div>
                  </a>
                </Card>
              ))}
            </CardGroup>
          </Col>
          
        </Row>
        {/* <ContactSvg /> */}
      </Container>
    </>
  )
};

export default Contact;
