import React from "react";
import CustomNav from "./NavBar";
import { Container } from "react-bootstrap";

const FourOhFour = () => {
  return (
    <>
    <Container className="home-container" role="main">
      <CustomNav />
      <div className="page-not-found">
        <img src="https://i.ibb.co/RSQNNYn/oops.jpg" alt="" />
        <h2>Page Not Found...!</h2>
      </div>
      </Container>
    </>
  )
};

export default FourOhFour;
